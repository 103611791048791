import requestJson from '#/helpers/requestJson';
import appConfig from '#/config/app';

const getToken = async (params) => {
  const { tokenCode } = params;
  const { sam3 } = appConfig;

  if (!tokenCode) {
    return { error: true, errorResponse: 'missing token code' };
  }

  // Get SAM3 Token
  const token = await requestJson({
    endpoint: sam3.tokenURL,
    opts: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      oauth: true,
      parseResponseForErrors: [400],
      body: {
        grant_type: 'authorization_code',
        client_id: sam3.clientID,
        redirect_uri: sam3.redirectURL,
        code: tokenCode
      }
    },
    requestType: 'sam3'
  });

  if (token.access_token && token.refresh_token) {
    return token;
  }

  return { ...token.errorResponse };
};

export default getToken;
