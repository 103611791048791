export const CODE_INPUT_PAGE = {
  HEADLINE: 'Aktiviere dein Gerät',
  DESCRIPTION:
    'Gib den Login-Code von deinem Gerät ein, um es mit deinem MagentaTV-Konto zu verbinden.',
  CONTINUE_BUTTON: 'Weiter',
  SIGNUP: 'Neu bei MagentaTV?',
  SIGNUP_BUTTON: 'Sign up'
};

export const CONFIRMATION_PAGE = {
  HEADLINE: 'Du hast dich erfolgreich auf dem TV angemeldet',
  CONFIRM_BUTTON: 'Entdecke MagentaTV im Web'
};

export const DT_WEB_URL = 'https://web.magentatv.de/';

export const HEADER = {
  SLOGAN: 'life is for sharing.',
  MAGENTA_TV: 'MagentaTV'
};

export const STORAGE = {
  STORAGE_LOGIN_CODE: 'STORAGE_LOGIN_CODE'
};

export const PATHS = {
  CODE_INPUT: '/',
  CONFIRM: '/authn/idm'
};

export const ERROR_MESSAGE = {
  CODE_INVALID: 'Der eingegebene Login-Code ist ungültig.',
  CODE_INCOMPLETE: 'Der eingegebene Login-Code ist unvollständig.',
  CODE_EXPIRED: 'Der eingegebene Login-Code ist abgelaufen.',
  CODE_INCORRECT: 'Der eingegebene Login-Code ist inkorrekt.',
  CODE_SAVE: 'Could not save to local storage.',
  TRY_AGAIN: 'Please try again.'
};

export const SAM3_TOKENS = {
  DCP: 'DCP',
  EPG: 'EPG',
  IMAGE: 'IMAGE',
  OPEN_ID: 'OPEN_ID',
  STREAM_MGR: 'STREAM_MGR',
  VOD: 'VOD'
};

export const ERROR_REQUEST_DESCRIPTION = {
  INVALID_AUTH_HEADER: 'invalid authn header (Bearer type expected)',
  INVALID_ACCESS_TOKEN: 'invalid access token',
  ACCESS_TOKEN_EXPIRED: 'access token TTL expired',
  UNKNOWN_LOGIN_CODE: 'unknown initial_login_code',
  ACCESS_TOKEN_USED: 'transaction terminated'
};

export const ACCESSIBILITY_LABEL = {
  LOGO: 'Telekom Logo',
  SLOGAN: 'Werbung – Erleben was verbindet',
  CONTINUE_BUTTON: 'Weiter, bitte nach Code-Eingabe bestätigen',
  CODE_INPUT_CONTAINER: 'Bitte 8-stelligen Login-Code eingeben',
  CODE_INPUT_FIELD: 'Bitte die # Ziffer des Login-Codes eingeben'
};
